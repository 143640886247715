
import 'alpinejs'


import Flickity from "flickity"
import "flickity-fade"
import "flickity-as-nav-for"
import { isMobile } from 'mobile-device-detect';


window.terranova = function (names) {
  return {
    loaded: false,
    openedNav:false,
    listen:false,
    carousel:null,
    active:-1,
    names:names,
    startClicked:false,
    showStart:false,
    finishedEnter:false,
    isTouch:isMobile,

    init(){


      this.carousel = new Flickity(this.$refs.carousel, {
        "draggable":this.isTouch,
        "prevNextButtons": false,
        "fade": true ,
        "cellAlign": "left",
        "contain": true,
        "autoPlay":false,

        accessibility: true
      })
      var flktyNav = new Flickity(this.$refs.carouselnav, {
        "groupCells":true, "asNavFor": ".carousel-main" ,"pageDots": false
      });
      this.active =0;
      this.carousel.on('change', i => this.active = i);

    },
    onClick()
    {
      if (!this.listen) return
      if((this.carousel.cells.length-1) <= this.active )this.carousel.select(0);
      else this.carousel.next();

    },
    startExpo()
    {
      this.startClicked = true;
      this.showStart = false;
      setTimeout( ()=> {
        this.hideAndReady()
      }, 3200)
    },

    onLoaded() {

     this.loaded = true;
      this.showStart = true;

    },
    hideAndReady() {
      this.openedNav = true;
      this.finishedEnter = true;
      setTimeout( ()=> {
        this.listen = true;
      }, 1000)

    },
    close() { this.show = false },
    isOpen() { return this.show === true },

    onMousemove(e)
    {
      let posY = e.screenY;
      var wh = window.innerHeight
      if (!this.listen) return
      if (posY > 100 && posY < (wh-100) ) this.openedNav = false;
      else this.openedNav = true;
    }
  }
}

//data-flickity='{  }'
